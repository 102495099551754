import { atom, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { getShortUniqueId } from 'services/uniqueId';

const { persistAtom } = recoilPersist();

interface IGuest {
  guestId: string;
}

export const guestAtom = atom<IGuest>({
  key: 'guestState',
  default: {
    guestId: getShortUniqueId(),
  },
  effects_UNSTABLE: [persistAtom],
});

export const useGuest = () => {
  const [guest] = useRecoilState(guestAtom);
  return guest;
};
