import { AnalyticsBrowser } from '@segment/analytics-next';
import { MiddlewareFunction } from '@segment/analytics-next/dist/types/plugins/middleware';

const isDev = process && process.env.NODE_ENV === 'development';
const isPreview = process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production';

export type JsonValue =
  | boolean
  | number
  | string
  | null
  | JsonList
  | JsonMap
  | undefined;

export interface JsonMap {
  [key: string]: JsonValue;
  [index: number]: JsonValue;
}

export interface JsonList extends Array<JsonValue> {}

const dummyClient = {
  page: (name: string, properties?: JsonMap) => {
    console.info('[Segment dummy]: PAGE', {
      name,
      properties,
    });
  },

  track: (event: string, properties?: JsonMap) => {
    console.info('[Segment dummy]: Track', {
      event,
      properties,
    });
  },

  identify: (id?: string | JsonMap, traits?: JsonMap) => {
    console.info('[Segment dummy]: Identify', {
      id,
      traits,
    });
  },
};

const getAnalyticsBrowser = () => {
  const analyticsBrowser = AnalyticsBrowser.load({
    writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
  });

  if (isPreview) {
    const logMiddleWare: MiddlewareFunction = ({ payload, next }) => {
      const { name, event, properties } = payload.rawEvent();
      console.log(name || event, properties);
      next(payload);
    };

    analyticsBrowser.addSourceMiddleware(logMiddleWare);
  }

  return analyticsBrowser;
};

export const segmentClient = isDev ? dummyClient : getAnalyticsBrowser();
