import { useCallback, useState } from 'react';

import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { useRouter } from 'next/router';

import { auth } from 'clients/firebase';

import { useClearUser, useFetchUser } from './user';

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: 'select_account',
});

export class NonVerifiedEmailError extends Error {
  name: string = 'NonVerifiedEmailError';
  message: string =
    "This email address hasn't been verified yet, please check your inbox and follow the instructions to verify your email address";
}

export const useEmailSignIn = () => {
  const { refetch } = useFetchUser();

  const { query, push } = useRouter();
  const [loading, setLoading] = useState(false);

  const emailSignInHandler = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      try {
        setLoading(true);

        const { user } = await signInWithEmailAndPassword(
          auth,
          email,
          password,
        );

        if (!user.emailVerified) {
          throw new NonVerifiedEmailError();
        }

        const idToken = await user.getIdToken();
        await fetch('/api/session/login', {
          method: 'POST',
          body: JSON.stringify({ idToken }),
        });

        await refetch();
        push((query.from as string) || `/${query.companySlug}`);
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [push, query.companySlug, query.from, refetch],
  );

  return {
    loading,
    emailSignInHandler,
  };
};

export const useEmailSignUp = () => {
  const { push } = useRouter();
  const [loading, setLoading] = useState(false);

  const emailSignUpHandler = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      try {
        setLoading(true);

        const { user } = await createUserWithEmailAndPassword(
          auth,
          email,
          password,
        );

        const urlWithOutHash = window.location.href.split('#')[0];
        await sendEmailVerification(user, {
          url: urlWithOutHash,
          handleCodeInApp: false,
        });

        push(`/unverified-user`);
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [push],
  );
  return {
    loading,
    emailSignUpHandler,
  };
};

export const useGoogleSignButton = () => {
  const { refetch } = useFetchUser();

  const [loading, setLoading] = useState(false);

  const onGoogleButtonPressHandler = useCallback(async () => {
    try {
      setLoading(true);

      const { user } = await signInWithPopup(auth, provider);

      const idToken = await user.getIdToken();

      await fetch('/api/session/login', {
        method: 'POST',
        body: JSON.stringify({ idToken }),
      });

      await refetch();
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, [refetch]);

  return {
    loading,
    onGoogleButtonPressHandler,
  };
};

export const useLogout = () => {
  const { clearUser } = useClearUser();
  const { push, query } = useRouter();

  const handleLogout = useCallback(async () => {
    await fetch('/api/session/logout', {
      method: 'POST',
    });

    clearUser();

    push(`/${query.companySlug}/login`);
  }, [clearUser, push, query.companySlug]);

  return {
    handleLogout,
  };
};
