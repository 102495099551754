export const enum EventTypes {
  STORY_VIEW_START = 'Story: View Start',
  STORY_VIEW_COMPLETED = 'Story: View Completed',
  STORY_VIEW_PROGRESS = 'Story: View Progress',
  STORY_REACTION = 'Story: Reaction',
  STORY_CREATION = 'Story: Creation',
  STORY_DELETION = 'Story: Deletion',
  STORY_PUBLISHING = 'Story: Publishing',
  STORY_UNPUBLISHING = 'Story: Unpublishing',
  STORY_COPIED_SHARED_LINK = 'Story: Copied share link',
  STORY_COPIED_EMBED_LINK = 'Story: Copied embedd link',
  STORY_LINK_ITEM_DISPLAYED = 'Story: Link item displayed',
  STORY_LINK_ITEM_CLICKED = 'Story: Link item clicked',
  STORY_POLL_ITEM_DISPLAYED = 'Story: Poll item displayed',
  STORY_POLL_ITEM_ANSWERED = 'Story: Poll item answered',
  STORY_QUIZ_ITEM_DISPLAYED = 'Story: Quiz item displayed',
  STORY_QUIZ_ITEM_ANSWERED = 'Story: Quiz item answered',
  STORY_QUESTION_ITEM_DISPLAYED = 'Story: Question item displayed',
  STORY_QUESTION_ITEM_ANSWERED = 'Story: Question item answered',
}

// Base interface tracking event
interface TrackingEvent {
  name: EventTypes;
  type: 'TRACK';
}

// Base interface tracking event data
interface TrackingEventData {
  meta: {
    userId: string | null;
    companySlug: string | null;
    environment: 'development' | 'preview' | 'staging';
    browser: {
      isMobile: boolean;
      name: string;
      os: string;
      type: string;
      version: string;
    };
  };
}

export interface StoryViewStartTrackingEventData extends TrackingEventData {
  storyId: string;
  title: string;
}
export interface StoryViewStartTrackingEvent extends TrackingEvent {
  data: StoryViewStartTrackingEventData;
}

export interface StoryViewCompleteTrackingEventData extends TrackingEventData {
  storyId: string;
  title: string;
  totalDuration: number;
}
export interface StoryViewCompleteTrackingEvent extends TrackingEvent {
  data: StoryViewCompleteTrackingEventData;
}

export interface StoryViewProgressTrackingEventData extends TrackingEventData {
  cardDuration: number;
  cardId: string;
  cardNumber: number;
  storyId: string;
  title: string;
  totalCards: number;
  totalDuration: number;
}
export interface StoryViewProgressTrackingEvent extends TrackingEvent {
  data: StoryViewProgressTrackingEventData;
}
