import Image from 'next/image';
import Link from 'next/link';

import { ISanitizedCompany } from 'types/company';

import { useEmailSignUp } from 'hooks/auth/sign';

import { AuthForm } from './AuthForm';
import { GoogleSignInButton } from './GoogleSignInButton';

interface IProps {
  switchSection(): void;
  company?: ISanitizedCompany;
}

export const SignUp = ({ switchSection, company }: IProps): JSX.Element => {
  const submitLabel = 'Register with email';

  const { emailSignUpHandler, loading } = useEmailSignUp();

  return (
    <article>
      <header className="flex flex-col items-center">
        <p className="text-sm font-[500]">
          <span className="text-black opacity-50 dark:text-white">
            Existing User? &nbsp;
          </span>

          <a
            className="cursor-pointer text-carnationRed hover:underline"
            onClick={switchSection}
          >
            Log-in
          </a>
        </p>

        {!!company?.logoUrl && !!company?.logoDarkUrl && (
          <>
            <div className="relative mt-6 block h-[45px] w-full dark:hidden">
              <Image
                src={company.logoUrl}
                alt={`${company.name} logo`}
                layout="fill"
                objectFit="contain"
                objectPosition={'absolute'}
              />
            </div>

            <div className="relative mt-6 hidden h-[45px] w-full dark:block">
              <Image
                src={company.logoDarkUrl}
                alt={`${company.name} logo`}
                layout="fill"
                objectFit="contain"
                objectPosition={'absolute'}
              />
            </div>
          </>
        )}

        <h2 className="mt-10 mb-2 text-center text-[46px] font-bold leading-tight text-carnationRed">
          Registration
        </h2>

        <p className="mb-9 text-center text-lg font-thin">
          Welcome to acehub. Create a free account and start enjoying your
          company stories.
        </p>
      </header>

      <div>
        <GoogleSignInButton />
      </div>

      <div className="my-8 flex flex-row items-center justify-between">
        <div className="flex-grow border-b border-b-[#C4C4C4]" />

        <p className="mx-2 text-[10px] font-semibold uppercase text-[#C4C4C4]">
          or
        </p>

        <div className="flex-grow border-b border-b-[#C4C4C4]" />
      </div>

      <AuthForm
        onSubmit={emailSignUpHandler}
        submitLabel={submitLabel}
        loading={loading}
      />

      <p className="mt-3 text-center text-xs text-black opacity-50 dark:text-white">
        By continuing, you agree to acehub’s &nbsp;
        <Link href="https://acehub.io/terms-conditions">
          <a
            target="_blank"
            rel="noreferrer noopener"
            className="font-bold hover:underline"
          >
            Terms &amp; Conditions
          </a>
        </Link>
      </p>
    </article>
  );
};
