import { useCallback, useEffect } from 'react';

import { detect } from 'detect-browser';
import { addDoc, collection } from 'firebase/firestore';
import isMobile from 'is-mobile';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

import { EventTypes } from 'types/tracking';

import { getUserIdFromCookie } from 'services/cookies';

import { db } from 'clients/firebase';
import { JsonMap, segmentClient } from 'clients/segment';

import { useGuest } from './auth/guest';

const browser = detect();

const shouldBeTracked = (userId: string) => {
  const USER_BLOCKLIST = [
    '19OF3WG44KZVklGyRgG9xjLBPjV2', // JP
    'oII58hgm5fWIX8Mzc08LbnDf3nm2', // Hannes
    '2Uk15SSyPJec2ON9pPGjVVYt4ph1', // Chris
    '8RaP0WJ9tdaB7FzWN0VrmEzynZG3', // Moritz
  ];

  return typeof userId === 'undefined' || !USER_BLOCKLIST.includes(userId);
};
const getEnrichedProps = (
  userId: string,
  query: ParsedUrlQuery,
  props?: JsonMap,
) => {
  return {
    ...props,
    meta: {
      userId: userId || null,
      companySlug: query.companySlug || null,
      environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
      browser: { ...browser, isMobile: isMobile() },
    },
  };
};

export const usePageTracking = (name: string, props?: JsonMap) => {
  const { query } = useRouter();
  const { guestId } = useGuest();

  useEffect(() => {
    (async () => {
      const userId = getUserIdFromCookie() || `guest-${guestId}`;

      if (shouldBeTracked(userId)) {
        const data = getEnrichedProps(userId, query, props);

        segmentClient.page(name, data);
      }
    })();
  }, [guestId, name, props, query, query.companySlug]);
};

export const usePageManualTracking = () => {
  const { query } = useRouter();
  const { guestId } = useGuest();

  const trackPage = useCallback(
    async (name: string, props?: JsonMap) => {
      const userId = getUserIdFromCookie() || `guest-${guestId}`;

      if (shouldBeTracked(userId)) {
        const data = getEnrichedProps(userId, query, props);

        segmentClient.page(name, data);
      }
    },
    [guestId, query],
  );

  return { trackPage };
};

export const useTracking = () => {
  const { query } = useRouter();
  const { guestId } = useGuest();

  const track = useCallback(
    async (name: string, props: JsonMap) => {
      const userId = getUserIdFromCookie() || `guest-${guestId}`;

      if (shouldBeTracked(userId)) {
        const data = getEnrichedProps(userId, query, props);

        segmentClient.track(name, data);
        await addDoc(collection(db, 'Events'), { type: 'TRACK', name, data });
      }
    },
    [guestId, query],
  );

  const trackStoryViewStart = useCallback(
    (props: { storyId: string; title: string }) =>
      track(EventTypes.STORY_VIEW_START, props),
    [track],
  );

  const trackStoryViewProgress = useCallback(
    (props: {
      storyId: string;
      title: string;
      cardNumber: number;
      totalCards: number;
      cardViewStart: string;
      cardDuration: number;
      totalDuration: number;
      cardId: string;
    }) => track(EventTypes.STORY_VIEW_PROGRESS, props),
    [track],
  );

  const trackStoryViewCompleted = useCallback(
    (props: { storyId: string; title: string; totalDuration: number }) =>
      track(EventTypes.STORY_VIEW_COMPLETED, props),
    [track],
  );

  const trackStoryReaction = useCallback(
    (props: { storyId: string; title: string; reaction: string }) =>
      track(EventTypes.STORY_REACTION, props),
    [track],
  );

  const trackStoryCreation = useCallback(
    (props: { storyId: string; createdAt: string }) =>
      track(EventTypes.STORY_CREATION, props),
    [track],
  );

  const trackStoryDeletion = useCallback(
    (props: { storyId: string }) => track(EventTypes.STORY_DELETION, props),
    [track],
  );

  const trackStoryPublishing = useCallback(
    (props: { storyId: string; publishedAt: string }) =>
      track(EventTypes.STORY_PUBLISHING, props),
    [track],
  );

  const trackStoryUnpublishing = useCallback(
    (props: { storyId: string }) => track(EventTypes.STORY_UNPUBLISHING, props),
    [track],
  );

  const trackStoryCopyShareLink = useCallback(
    (props: { storyId: string }) =>
      track(EventTypes.STORY_COPIED_SHARED_LINK, props),
    [track],
  );

  const trackStoryCopyEmbedCode = useCallback(
    (props: { storyId: string }) =>
      track(EventTypes.STORY_COPIED_EMBED_LINK, props),
    [track],
  );

  const trackCardItemLinkDisplay = useCallback(
    (props: { storyId: string; link: string }) =>
      track(EventTypes.STORY_LINK_ITEM_DISPLAYED, props),
    [track],
  );

  const trackCardItemLinkClick = useCallback(
    (props: { storyId: string; link: string }) =>
      track(EventTypes.STORY_LINK_ITEM_CLICKED, props),
    [track],
  );

  const trackCardItemPollDisplay = useCallback(
    (props: { storyId: string; pollId: string; question: string }) =>
      track(EventTypes.STORY_POLL_ITEM_DISPLAYED, props),
    [track],
  );

  const trackCardItemPollAnswer = useCallback(
    (props: { storyId: string; pollId: string; question: string }) =>
      track(EventTypes.STORY_POLL_ITEM_ANSWERED, props),
    [track],
  );

  const trackCardItemQuizDisplay = useCallback(
    (props: { storyId: string; quizId: string; question: string }) =>
      track(EventTypes.STORY_QUIZ_ITEM_DISPLAYED, props),
    [track],
  );

  const trackCardItemQuizAnswer = useCallback(
    (props: { storyId: string; quizId: string; question: string }) =>
      track(EventTypes.STORY_QUIZ_ITEM_ANSWERED, props),
    [track],
  );

  const trackCardItemQuestionDisplay = useCallback(
    (props: { storyId: string; questionId: string; question: string }) =>
      track(EventTypes.STORY_QUESTION_ITEM_DISPLAYED, props),
    [track],
  );

  const trackCardItemQuestionAnswer = useCallback(
    (props: { storyId: string; questionId: string; question: string }) =>
      track(EventTypes.STORY_QUESTION_ITEM_ANSWERED, props),
    [track],
  );

  return {
    trackStoryViewStart,
    trackStoryViewProgress,
    trackStoryViewCompleted,
    trackStoryReaction,
    trackStoryCreation,
    trackStoryDeletion,
    trackStoryPublishing,
    trackStoryUnpublishing,
    trackStoryCopyShareLink,
    trackStoryCopyEmbedCode,
    trackCardItemLinkDisplay,
    trackCardItemLinkClick,
    trackCardItemPollDisplay,
    trackCardItemPollAnswer,
    trackCardItemQuizDisplay,
    trackCardItemQuizAnswer,
    trackCardItemQuestionDisplay,
    trackCardItemQuestionAnswer,
  };
};
