import { useCallback, useEffect, useState } from 'react';

export type TSection = 'sign-in' | 'sign-up';

interface ISectionsHookParams {
  initialSection: TSection;
}

export const useSections = ({ initialSection }: ISectionsHookParams) => {
  const [currentSection, setCurrentSection] =
    useState<TSection>(initialSection);

  useEffect(() => {
    const { hash } = window.location;

    if (hash) {
      const section = hash.split('#')[1];

      if (['sign-up', 'sign-in'].includes(section)) {
        setCurrentSection(section as TSection);
      }
    }
  }, []);

  const handleSwitchSection = useCallback(() => {
    setCurrentSection(section => {
      switch (section) {
        case 'sign-in':
          return 'sign-up';
        case 'sign-up':
          return 'sign-in';
      }
    });
  }, []);

  useEffect(() => {
    if (currentSection) {
      window.location.hash = currentSection;
    }
  }, [currentSection]);

  return {
    currentSection,
    handleSwitchSection,
  };
};
