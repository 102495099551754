import { useGoogleSignButton } from 'hooks/auth/sign';

import { Spinner } from 'components/shared/Spinner';

import { GoogleIcon } from './GoogleIcon';

export const GoogleSignInButton = (): JSX.Element => {
  const { onGoogleButtonPressHandler, loading } = useGoogleSignButton();

  return (
    <div className="w-full rounded-[10px] bg-white shadow hover:shadow-md active:shadow-inner">
      <button
        className="relative flex w-full flex-row items-center justify-center disabled:opacity-50"
        disabled={loading}
        onClick={onGoogleButtonPressHandler}
      >
        <GoogleIcon />

        <span className="ml-4 py-4 align-middle text-[#858585]">
          Continue with Google
        </span>

        {loading && (
          <span className="ml-3">
            <Spinner className="text-black" size={24} />
          </span>
        )}
      </button>
    </div>
  );
};
