import { motion } from 'framer-motion';
import Image from 'next/image';

import { ISanitizedCompany } from 'types/company';

import { useFadeInTransition } from 'hooks/browser/transitions';

import { FooterMenu } from 'components/shared/layout/FooterMenu';

import illustration from '../../../../../public/authentication/illustration.png';

import { TSection, useSections } from './AuthProtection.hooks';
import { SignIn } from './SignIn';
import { SignUp } from './SignUp';

interface IProps {
  company?: ISanitizedCompany;
  initialSection?: TSection;
}

export const AuthProtection = ({
  company,
  initialSection = 'sign-in',
}: IProps): JSX.Element => {
  const { currentSection, handleSwitchSection } = useSections({
    initialSection,
  });
  const { container, item } = useFadeInTransition();

  return (
    <section className="flex h-full items-stretch bg-white dark:bg-codGrey">
      <div className="fixed hidden h-full w-[50%] bg-culturedPearlGrey dark:bg-white dark:bg-opacity-10 md:block">
        <div className="mx-auto flex h-full flex-col items-center justify-center p-4">
          <div className="max-w-[400px]">
            <Image
              src={illustration}
              alt="Bite illustration"
              objectFit="contain"
            />
          </div>

          <div className="max-w-[480px]">
            <h2 className="mb-4 mt-10 text-center text-4xl font-bold">
              No more boring information!
            </h2>

            <p className="text-center text-lg font-thin">
              Unleash your insights by telling stories that can be shared via
              existing channels or the acehub app.
            </p>
          </div>
        </div>
      </div>

      <div className="flex min-h-screen flex-grow flex-col md:pl-[50%]">
        <div className="flex h-full w-full flex-col items-center justify-center">
          <div className="w-full max-w-[400px] px-4 pt-12 pb-6">
            <motion.div variants={container} initial="hidden" animate="show">
              {currentSection === 'sign-up' ? (
                <motion.section key={0} variants={item}>
                  <SignUp
                    company={company}
                    switchSection={handleSwitchSection}
                  />
                </motion.section>
              ) : (
                <motion.section key={1} variants={item}>
                  <SignIn
                    company={company}
                    switchSection={handleSwitchSection}
                  />
                </motion.section>
              )}
            </motion.div>
          </div>
        </div>

        <div className="flex-shrink-0 self-center justify-self-end py-7 md:self-end md:px-16">
          <FooterMenu />
        </div>
      </div>
    </section>
  );
};
