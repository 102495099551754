import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Field, FieldProps, useField } from 'formik';

interface IProps {
  variant?: 'normal' | 'medium' | 'small';
  updateOnChange?: boolean;
  label?: string;
  onUpdate?: (value: string) => void;
}

export const TextField = ({
  name = '',
  label,
  onKeyDown,
  onChange,
  onBlur,
  onUpdate,
  variant = 'normal',
  updateOnChange = false,
  type = 'text',
  className,
  ...props
}: JSX.IntrinsicElements['input'] & IProps) => {
  const [input] = useField(name);
  const [internalValue, setInternalValue] = useState<string>(input.value);

  useEffect(() => {
    if (input.value !== internalValue) {
      setInternalValue(input.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.value]);

  return (
    <div>
      {!!label && <div className="mb-[6px] text-xs opacity-50">{label}</div>}

      <Field name={name}>
        {({ field: { onChange, onBlur, name } }: FieldProps) => {
          return (
            <input
              {...props}
              name={name}
              value={internalValue}
              onChange={e => {
                setInternalValue(e.currentTarget.value);
                onUpdate?.(e.currentTarget.value);

                if (updateOnChange) {
                  onChange(e);
                }
              }}
              onBlur={e => {
                onChange(e);
                onBlur(e);
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  onChange(e);
                }

                onKeyDown?.(e);
              }}
              type={type}
              className={classNames(
                'h-[54px] rounded-lg border border-black/5 bg-white/90 p-[10px] outline-2 outline-bigStoneBlue focus-visible:outline dark:bg-white/[8%] dark:outline-white',
                className,
                {
                  ['!h-[40px]']: variant === 'medium',
                  ['!h-[36px]']: variant === 'small',
                },
              )}
            />
          );
        }}
      </Field>
    </div>
  );
};
