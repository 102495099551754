import Image from 'next/image';

import { ISanitizedCompany } from 'types/company';

import { useEmailSignIn } from 'hooks/auth/sign';

import { AuthForm } from './AuthForm';
import { GoogleSignInButton } from './GoogleSignInButton';

interface IProps {
  switchSection(): void;
  company?: ISanitizedCompany;
}

export const SignIn = ({ switchSection, company }: IProps): JSX.Element => {
  const submitLabel = 'Log-in with Email';

  const { emailSignInHandler, loading } = useEmailSignIn();

  return (
    <article>
      <header className="flex flex-col items-center">
        <p className="text-sm font-[500]">
          <span className="text-black opacity-50 dark:text-white">
            New User? &nbsp;
          </span>

          <a
            className="cursor-pointer text-carnationRed hover:underline"
            onClick={switchSection}
          >
            Register
          </a>
        </p>

        {!!company?.logoUrl && !!company?.logoDarkUrl && (
          <>
            <div className="relative mt-6 block h-[45px] w-full dark:hidden">
              <Image
                src={company.logoUrl}
                alt={`${company.name} logo`}
                layout="fill"
                objectFit="contain"
                objectPosition={'absolute'}
              />
            </div>

            <div className="relative mt-6 hidden h-[45px] w-full dark:block">
              <Image
                src={company.logoDarkUrl}
                alt={`${company.name} logo`}
                layout="fill"
                objectFit="contain"
                objectPosition={'absolute'}
              />
            </div>
          </>
        )}

        <h2 className="mt-10 mb-2 text-center text-[46px] font-bold leading-tight text-carnationRed">
          Login
        </h2>

        <p className="mb-9 text-center text-lg font-thin">
          Hi there! Sign-in with your account and start enjoying your company
          stories.
        </p>
      </header>

      <div>
        <GoogleSignInButton />
      </div>

      <div className="my-8 flex flex-row items-center justify-between">
        <div className="flex-grow border-b border-b-[#C4C4C4]" />

        <p className="mx-2 text-[10px] font-semibold uppercase text-[#C4C4C4]">
          or
        </p>

        <div className="flex-grow border-b border-b-[#C4C4C4]" />
      </div>

      <AuthForm
        onSubmit={emailSignInHandler}
        submitLabel={submitLabel}
        loading={loading}
      />

      <div className=" mb-8 md:mb-9"></div>
    </article>
  );
};
