import { useEffect } from 'react';

import { doc, getDoc } from 'firebase/firestore';
import { useRouter } from 'next/router';

import { ICompany } from 'types/company';

import { db } from 'clients/firebase';

import { useUser } from './user';

export const useRedirectToUserCompany = () => {
  const { push, query } = useRouter();
  const { user } = useUser();

  useEffect(() => {
    (async () => {
      if (user?.profile?.companyId) {
        if (query.from) {
          return push(query.from as string);
        }

        const companyId =
          user.profile.companyId !== '*'
            ? user?.profile.companyId
            : process.env.NEXT_PUBLIC_DEFAULT_COMPANY_ID;

        const companyDoc = await getDoc(doc(db, `/Companies/${companyId}`));

        push(`/${(companyDoc.data() as ICompany).slug}`);
      }
    })();
  }, [push, query.companySlug, query.from, user]);
};
