import { parse } from 'cookie';
import jwtDecode from 'jwt-decode';

export const getUserIdFromToken = (token: string): string => {
  const { user_id } = jwtDecode(token) as { user_id: string };

  return user_id;
};

export const getUserIdFromCookie = (): string | null => {
  const cookies = parse(document.cookie);
  return cookies.acehub_user_id || null;
};
