import { useCallback, useState } from 'react';

import { AuthError } from 'firebase/auth';

import { authError } from 'services/authError';

import { NonVerifiedEmailError } from 'hooks/auth/sign';

import { TSubmit } from './AuthForm';

interface IFormHookParams {
  onSubmit: (credentials: { email: string; password: string }) => Promise<void>;
}

export const useForm = ({ onSubmit }: IFormHookParams) => {
  const [error, setError] = useState('');

  const submitHandler = useCallback<TSubmit>(
    async ({ email, password }) => {
      try {
        await onSubmit({ email, password });
      } catch (e) {
        if (e instanceof NonVerifiedEmailError) {
          setError(e.message);
        } else {
          const error = e as AuthError;
          setError(authError(error.code));
        }
      }
    },
    [onSubmit, setError],
  );

  return { error, submitHandler };
};
